// Generated by Framer (f082bd6)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["g7hCTB0og", "fmrbzGv7Y"];

const serializationHash = "framer-GfN4h"

const variantClassNames = {fmrbzGv7Y: "framer-v-15w5asi", g7hCTB0og: "framer-v-bpztr7"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "g7hCTB0og", "Variant 2": "fmrbzGv7Y"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "g7hCTB0og"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "g7hCTB0og", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (baseVariant === "fmrbzGv7Y") return false
return true
}

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-bpztr7", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"g7hCTB0og"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({fmrbzGv7Y: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-z2u5zm"} layoutDependency={layoutDependency} layoutId={"blQp3qJbg"} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 12, borderBottomRightRadius: 12, borderTopLeftRadius: 12, borderTopRightRadius: 12, rotate: 0}} variants={{fmrbzGv7Y: {rotate: -45}}}/>{isDisplayed() && (<motion.div className={"framer-1wixb81"} layoutDependency={layoutDependency} layoutId={"wefiU78pG"} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 12, borderBottomRightRadius: 12, borderTopLeftRadius: 12, borderTopRightRadius: 12}}/>)}<motion.div className={"framer-ozvhwz"} layoutDependency={layoutDependency} layoutId={"GM56Lif2x"} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 12, borderBottomRightRadius: 12, borderTopLeftRadius: 12, borderTopRightRadius: 12, rotate: 0}} variants={{fmrbzGv7Y: {rotate: 45}}}/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-GfN4h.framer-1nszpem, .framer-GfN4h .framer-1nszpem { display: block; }", ".framer-GfN4h.framer-bpztr7 { height: 200px; position: relative; width: 200px; }", ".framer-GfN4h .framer-z2u5zm { flex: none; height: 20px; left: calc(50.00000000000002% - 122px / 2); overflow: visible; position: absolute; top: 50px; width: 122px; }", ".framer-GfN4h .framer-1wixb81 { flex: none; height: 20px; left: calc(50.00000000000002% - 122px / 2); overflow: visible; position: absolute; top: calc(50.00000000000002% - 20px / 2); width: 122px; }", ".framer-GfN4h .framer-ozvhwz { bottom: 50px; flex: none; height: 20px; left: calc(50.00000000000002% - 122px / 2); overflow: visible; position: absolute; width: 122px; }", ".framer-GfN4h.framer-v-15w5asi .framer-z2u5zm { top: calc(50.00000000000002% - 20px / 2); }", ".framer-GfN4h.framer-v-15w5asi .framer-ozvhwz { bottom: unset; top: calc(50.00000000000002% - 20px / 2); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 200
 * @framerIntrinsicWidth 200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"fmrbzGv7Y":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerwjg7K1mr1: React.ComponentType<Props> = withCSS(Component, css, "framer-GfN4h") as typeof Component;
export default Framerwjg7K1mr1;

Framerwjg7K1mr1.displayName = "Ícone Menu";

Framerwjg7K1mr1.defaultProps = {height: 200, width: 200};

addPropertyControls(Framerwjg7K1mr1, {variant: {options: ["g7hCTB0og", "fmrbzGv7Y"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Framerwjg7K1mr1, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})